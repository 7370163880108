// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-page-tsx": () => import("./../../../src/pages/blogPage.tsx" /* webpackChunkName: "component---src-pages-blog-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-map-tsx": () => import("./../../../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-gallery-template-tsx": () => import("./../../../src/templates/GalleryTemplate.tsx" /* webpackChunkName: "component---src-templates-gallery-template-tsx" */),
  "component---src-templates-map-content-template-tsx": () => import("./../../../src/templates/MapContentTemplate.tsx" /* webpackChunkName: "component---src-templates-map-content-template-tsx" */),
  "component---src-templates-map-template-tsx": () => import("./../../../src/templates/MapTemplate.tsx" /* webpackChunkName: "component---src-templates-map-template-tsx" */),
  "component---src-templates-photo-template-tsx": () => import("./../../../src/templates/PhotoTemplate.tsx" /* webpackChunkName: "component---src-templates-photo-template-tsx" */)
}

