import React, { useState, FunctionComponent } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

interface yearData {
  allWpPage: {
    edges: {
      node: {
        id: number
        slug: string
        title: string
        parentDatabaseId: number
        databaseId: number
      }
    }[]
  }
}
export interface yearType {
  slug: string
  title: string
  id: number
  countries: {
    title: string
    slug: string
  }[]
}

export interface NavType {
  currentPage: string | null
  previousPage: string | null
}

export interface ContextType {
  nav: NavType
  setNav: React.Dispatch<React.SetStateAction<NavType>>
  years: yearType[]
}
export const GlobalContext = React.createContext<ContextType | null>(null)

const GlobalContextProvider: FunctionComponent = ({ children }) => {
  const data: yearData = useStaticQuery(graphql`
    query YearQuery {
      allWpPage(filter: {}, sort: { fields: title, order: DESC }) {
        edges {
          node {
            id
            slug
            title
            parentDatabaseId
            databaseId
          }
        }
      }
    }
  `)
  const root = data.allWpPage.edges.find((el) => el.node.slug === 'root')?.node
    .databaseId
  const years1 = data.allWpPage.edges.filter(
    (el) => el.node.parentDatabaseId === root
  )
  let years: yearType[] = years1.map((el) => {
    let countries1 = data.allWpPage.edges.filter(
      (elInner) => elInner.node.parentDatabaseId === el.node.databaseId
    )
    let countries = countries1.map((elInner) => ({
      title: elInner.node.title,
      slug: elInner.node.slug,
    }))
    return {
      slug: el.node.slug,
      title: el.node.title,
      id: el.node.databaseId,
      countries,
    }
  })
  const [nav, setNav] = useState<NavType>({
    currentPage: null,
    previousPage: null,
  })
  const [galleryPhotos, setGalleryPhotos] = useState([])
  return (
    <GlobalContext.Provider value={{ nav, setNav, years }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
